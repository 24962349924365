<template>
	<div
		class="d-flex flex-column align-center justify-center"
		style="height: 100vh; width: 100vw"
	>
		<div class="d-flex flex-column align-center justify-center mt-n16">
			<img src="@/assets/img/under-construction.png" width="180px" />
			<div class="text-h4 my-2">Po punohet në këtë faqe</div>
			<div class="text-h5 my-2">Do jetë gati së shpejti!</div>

			<v-btn
				@click="$router.replace('/')"
				color="primary"
				class="mt-4"
				depressed
			>
				Kthehu
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: "UnderConstruction",
};
</script>

<style scoped></style>
